<template>
  <div class="chat-content">
    <ChatMessages v-if="chat" :chat="chat" :shop="shop" @remove-chat="removeChat" />
    <ChatList v-else @set-chat="setChat" @set-chat-admin="setChatAdmin" />
  </div>
</template>

<script>
import ChatList from "./ChatContent/ChatList.vue";
import ChatMessages from "./ChatContent/ChatMessages.vue";
import { eventBus } from "../../../utils";

export default {
  components: { ChatList, ChatMessages },
  data() {
    return {
      shop: null,
      chat: null,
    };
  },
  mounted() {
    eventBus.$on("set-chat", (shop, chat) => {
      this.setChat(shop, chat);
    });
  },
  methods: {
    setChat(shop, chat) {
      this.shop = shop;
      this.chat = chat;
    },
    setChatAdmin(chat) {
      this.chat = chat;
    },
    removeChat() {
      this.chat = null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.chat-content{
  background-color #fff
  border-radius 10px
  box-shadow: 4px 0 24px rgba(0, 0, 0, .12);
  overflow hidden
  height: 600px;
  max-height: calc(100vh - 212px);
  min-height: 360px;
}
</style>
