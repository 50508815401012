<template>
  <div class="chat">
    <ChatContent
      class="chat__content"
      :class="{
        'chat__content--open': chatOpen,
      }"
    ></ChatContent>
    <div class="chat__button chat-button" @click="chatOpen = !chatOpen">
      <div class="chat-button__icon">
        <FileIconComponent class="chat-button__svg" name="message-lines" />
      </div>
    </div>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import ChatContent from "./components/ChatContent.vue";
import { eventBus } from "../../utils";

export default {
  components: {
    FileIconComponent,
    ChatContent,
  },
  mounted() {
    eventBus.$on("chatOpen", (value) => {
      this.chatOpen = value;
    });
  },
  data() {
    return {
      chatOpen: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.chat {
  &__content{
    position fixed
    display none
    right 20px
    bottom 100px
    z-index 99

    width 400px

    &--open{
      display block
    }
  }

  &__button{
    cursor pointer
    position fixed
    z-index 99
    right 20px
    bottom 20px
  }
}

.chat-button{
  &__icon{
    position relative
    width 60px
    height: 60px
    border-radius 50%
    background-color var(--accent)
  }

  &__svg {
    position relative
    width 30px
    left 50%
    top 50%
    fill #fff

    transform: translate(-50%, -50%)
  }
}
</style>
