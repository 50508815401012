var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-content-list"},[_c('div',{staticClass:"chat-content-list__container"},[_vm._m(0),(_vm.shops.length > 0)?_c('div',{staticClass:"chat-content-list__body chat-content-list-body"},[_c('div',{staticClass:"chat-content-list-body__item",class:{
          'chat-content-list-body__item--unseen': _vm.chatAdmin
            ? _vm.chatAdmin.count_messages_not_seen_count > 0
            : false,
        },on:{"click":_vm.setChatAdmin}},[_c('div',{staticClass:"chat-content-list-body__left"},[_c('div',{staticClass:"chat-content-list-body__avatar"}),_c('div',{staticClass:"chat-content-list-body__content"},[_c('h6',{staticClass:"chat-content-list-body__name"},[_vm._v("Техническая поддержка")]),(_vm.chatAdmin && _vm.chatAdmin?.latestMessage)?_c('span',{staticClass:"chat-content-list-body__message"},[_vm._v("\n              "+_vm._s(_vm.chatAdmin.latestMessage?.message)+"\n            ")]):_vm._e()])]),(_vm.chatAdmin)?_c('div',{staticClass:"chat-content-list-body__right"},[(_vm.chatAdmin.latestMessage)?_c('div',{staticClass:"chat-content-list-body__time"},[_c('span',[_vm._v(_vm._s(_vm.chatTimeConvert(_vm.chatAdmin.latestMessage?.created_at)))])]):_vm._e(),(_vm.chatAdmin.count_messages_not_seen_count > 0)?_c('div',{staticClass:"chat-content-list-body__seen"},[_c('span',[_vm._v(_vm._s(_vm.chatAdmin.count_messages_not_seen_count))])]):_vm._e()]):_vm._e()]),_vm._l((_vm.shops),function(item,key){return _c('div',{key:key,staticClass:"chat-content-list-body__item",class:{
          'chat-content-list-body__item--unseen': item.chat
            ? item.chat.count_messages_not_seen_count > 0
            : false,
        },on:{"click":function($event){return _vm.setShop(item)}}},[_c('div',{staticClass:"chat-content-list-body__left"},[_c('div',{staticClass:"chat-content-list-body__avatar"}),_c('div',{staticClass:"chat-content-list-body__content"},[_c('h6',{staticClass:"chat-content-list-body__name"},[_vm._v(_vm._s(item.title))]),(item.chat?.latestMessage)?_c('span',{staticClass:"chat-content-list-body__message"},[_vm._v("\n              "+_vm._s(item.chat.latestMessage?.message)+"\n            ")]):_vm._e()])]),_c('div',{staticClass:"chat-content-list-body__right"},[(item.chat && item.chat?.latestMessage)?_c('div',{staticClass:"chat-content-list-body__time"},[_c('span',[_vm._v(_vm._s(_vm.chatTimeConvert(item.chat.latestMessage?.created_at)))])]):_vm._e(),(item.chat && item.chat.count_messages_not_seen_count > 0)?_c('div',{staticClass:"chat-content-list-body__seen"},[_c('span',[_vm._v(_vm._s(item.chat.count_messages_not_seen_count))])]):_vm._e()])])})],2):(!_vm.user)?_c('div',{staticClass:"chat-content-list__none"},[_vm._v("Для общения в чате требуется авторизация")]):(_vm.isLoading)?_c('div',{staticClass:"chat-content-list__none"},[_vm._v("Чаты загружаются..")]):_c('div',{staticClass:"chat-content-list__none"},[_vm._v("Чаты отсутствуют")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-content-list__header"},[_c('h4',{staticClass:"chat-content-list__title"},[_vm._v("Чат")])])
}]

export { render, staticRenderFns }