<template>
  <div class="chat-content-list">
    <div class="chat-content-list__container">
      <div class="chat-content-list__header">
        <h4 class="chat-content-list__title">Чат</h4>
      </div>
      <div v-if="shops.length > 0" class="chat-content-list__body chat-content-list-body">
        <div
          class="chat-content-list-body__item"
          @click="setChatAdmin"
          :class="{
            'chat-content-list-body__item--unseen': chatAdmin
              ? chatAdmin.count_messages_not_seen_count > 0
              : false,
          }"
        >
          <div class="chat-content-list-body__left">
            <div class="chat-content-list-body__avatar">
              <!-- <ImgComponent :head_img="item.head_img" /> -->
            </div>
            <div class="chat-content-list-body__content">
              <h6 class="chat-content-list-body__name">Техническая поддержка</h6>
              <span v-if="chatAdmin && chatAdmin?.latestMessage" class="chat-content-list-body__message">
                {{ chatAdmin.latestMessage?.message }}
              </span>
            </div>
          </div>
          <div v-if="chatAdmin" class="chat-content-list-body__right">
            <div v-if="chatAdmin.latestMessage" class="chat-content-list-body__time">
              <span>{{ chatTimeConvert(chatAdmin.latestMessage?.created_at) }}</span>
            </div>
            <div v-if="chatAdmin.count_messages_not_seen_count > 0" class="chat-content-list-body__seen">
              <span>{{ chatAdmin.count_messages_not_seen_count }}</span>
            </div>
          </div>
        </div>

        <div
          v-for="(item, key) in shops"
          :key="key"
          class="chat-content-list-body__item"
          @click="setShop(item)"
          :class="{
            'chat-content-list-body__item--unseen': item.chat
              ? item.chat.count_messages_not_seen_count > 0
              : false,
          }"
        >
          <div class="chat-content-list-body__left">
            <div class="chat-content-list-body__avatar">
              <!-- <ImgComponent :head_img="item.head_img" /> -->
            </div>
            <div class="chat-content-list-body__content">
              <h6 class="chat-content-list-body__name">{{ item.title }}</h6>
              <span v-if="item.chat?.latestMessage" class="chat-content-list-body__message">
                {{ item.chat.latestMessage?.message }}
              </span>
            </div>
          </div>
          <div class="chat-content-list-body__right">
            <div v-if="item.chat && item.chat?.latestMessage" class="chat-content-list-body__time">
              <span>{{ chatTimeConvert(item.chat.latestMessage?.created_at) }}</span>
            </div>
            <div
              v-if="item.chat && item.chat.count_messages_not_seen_count > 0"
              class="chat-content-list-body__seen"
            >
              <span>{{ item.chat.count_messages_not_seen_count }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!user" class="chat-content-list__none">Для общения в чате требуется авторизация</div>
      <div v-else-if="isLoading" class="chat-content-list__none">Чаты загружаются..</div>
      <div v-else class="chat-content-list__none">Чаты отсутствуют</div>
    </div>
  </div>
</template>

<script>
import CHAT_ADMIN from "@/graphql/queries/chat_admin.graphql";
import SHOPS_WITH_CHATS from "@/graphql/queries/shops_with_chats.graphql";
import CHAT_CREATE from "@/graphql/mutations/ChatCreate.graphql";
import CHAT_ADMIN_CREATE from "@/graphql/mutations/ChatAdminCreate.graphql";
import moment from "moment";

export default {
  mounted() {
    if (!this.user) return false;

    this.getChatAdmin();
    this.getShops();
    this.initChannelChats();
  },
  data() {
    return {
      chatAdmin: null,
      // shops: [],
      isLoading: true,
    };
  },
  methods: {
    initChannelChats() {
      window.Echo.private(`user-chat-channel.${this.user.id}`).listen("ChatUpdateEvent", () => {
        this.getChatAdmin();
        this.getShops();
      });
    },
    async setShop(shop) {
      if (!shop.chat) {
        this.createChat(shop).then(async (data) => {
          if (data) {
            await this.getShops();
            this.$emit("set-chat", shop, data);
          }
        });
      } else {
        this.$emit("set-chat", shop, shop.chat);
      }
    },
    async setChatAdmin() {
      if (!this.chatAdmin) {
        this.createChatAdmin().then(async (data) => {
          if (data) {
            await this.getChatAdmin();
            this.$emit("set-chat-admin", data);
          }
        });
      } else {
        this.$emit("set-chat-admin", this.chatAdmin);
      }
    },
    getChatAdmin() {
      this.loading = true;

      this.$apollo
        .query({
          query: CHAT_ADMIN,
          fetchPolicy: "no-cache",
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.chat_admin) this.chatAdmin = data.chat_admin;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    getShops() {
      this.loading = true;

      this.$apollo
        .query({
          query: SHOPS_WITH_CHATS,
          fetchPolicy: "no-cache",
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.shops_with_chats) this.$store.state.shops = data.shops_with_chats;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    async createChat(shop) {
      this.loading = true;

      return this.$apollo
        .mutate({
          mutation: CHAT_CREATE,
          variables: {
            shop_id: shop.id,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          return data?.ChatCreate?.chat ?? false;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    async createChatAdmin() {
      this.loading = true;

      return this.$apollo
        .mutate({
          mutation: CHAT_ADMIN_CREATE,
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          return data?.ChatAdminCreate?.chat ?? false;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    chatTimeConvert(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    apollo_token() {
      return this.$store.state.apollo_token;
    },
    shops() {
      return this.$store.state.shops;
    },
  },
};
</script>

<style lang="stylus" scoped>
.chat-content-list{
  height: 100%;

  &__container{
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding 25px 25px 0 25px
  }

  &__body{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  &__none {
    padding 10px 25px
  }
}

.chat-content-list-body{
  &__item{
    padding 10px 20px
    margin: 5px 0
    cursor pointer
    display flex
    justify-content: space-between;

    &:hover, &:active {
      background-color #F6F7F8
    }

    &--unseen {
      background-color #F6F7F8
    }
  }

  &__right{
    text-align right
  }

  &__content {
    overflow: hidden;
  }

  &__avatar{
    position relative
    border-radius 50%
    flex-shrink: 0;
    margin-right 15px

    & > img {
      width 40px
      height: 40px;
      object-fit: cover;
      border-radius 50%
    }
  }

  &__name{
    font-size 14px
  }

  &__message{
    white-space: nowrap;
  }

  &__time{
    & > span {
      color #adadad
    }
  }

  &__seen {
    & > span {
      background-color var(--accent)
      color #fff
      padding 2px 10px
      border-radius 20px
      font-size 13px
    }
  }
}
</style>
