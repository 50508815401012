<template>
  <div class="chat-messages">
    <div class="chat-messages__container">
      <div class="chat-messages__header">
        <span class="chat-messages__back" @click="removeChat">←</span>
        <div class="chat-messages__title chat-messages-title">
          <div class="chat-messages-title__avatar">
            <img src="/static/images/no-image.png" />
          </div>
          <div class="chat-messages-title__name">
            <h5 v-if="shop" class="chat-messages-title__title">{{ shop.title }}</h5>
            <h5 v-else class="chat-messages-title__title">Техническая поддержка</h5>
            <small class="chat-messages-title__subtitle">{{ chat.subtitle }}</small>
          </div>
        </div>
      </div>
      <div ref="chatMessagesBody" class="chat-messages__body chat-messages-body">
        <div class="chat-messages-body__container">
          <div
            v-for="(item, key) in messages"
            :key="key"
            class="chat-messages-body__item chat-messages-body-item"
            :class="{
              'chat-messages-body-item--my': item.is_my,
            }"
          >
            <div
              class="chat-messages-body-item__content"
              :class="{
                'chat-messages-body-item__content--my': item.is_my,
              }"
              v-observe-visibility="(state) => updateSeen(state, item, key)"
            >
              <h6
                class="chat-messages-body-item__name"
                :class="{
                  'chat-messages-body-item__name--my': item.is_my,
                }"
              >
                {{ item.user?.name }}
              </h6>
              <div class="chat-messages-body-item__text">
                <p
                  v-if="item.message"
                  class="chat-messages-body-item__message"
                  :class="{
                    'chat-messages-body-item__message--my': item.is_my,
                  }"
                >
                  {{ item.message }}
                </p>
                <div v-if="item.attachment" class="chat-messages-body-item__attachment">
                  <img :src="getImg(item.attachment)" />
                </div>
                <span
                  class="chat-messages-body-item__time"
                  :class="{
                    'chat-messages-body-item__time--my': item.is_my,
                  }"
                >
                  {{ chatTimeConvert(item.created_at) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form @submit="sendMessageHandle" class="chat-messages__actions chat-messages-actions">
        <input type="file" accept="image/*" ref="file" v-show="false" @change="filesUpload" />
        <button
          @click.prevent="$refs.file.click()"
          type="button"
          :disabled="loading"
          class="chat-messages-actions__file btn btn--sm btn--hollow-dark"
        >
          <FileIconComponent class="chat-messages-actions__file-icon" name="paperclip" />
        </button>
        <InputComponent
          class="chat-messages-actions__message"
          v-model.trim="message"
          type="text"
          :disabled="loading"
          placeholder="Напишите сообщение..."
        />
        <button type="submit" class="chat-messages-actions__send" :disabled="message.length === 0 || loading">
          <FileIconComponent class="chat-messages-actions__send-icon" name="arrow-up" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import CHAT_MESSAGES from "@/graphql/queries/chat_messages_paginate.graphql";
import SEND_MESSAGE from "@/graphql/mutations/ChatMessageCreate.graphql";
import FILES_UPLOAD from "@/graphql/mutations/FilesUpload.graphql";
import UPDATE_SEEN_MESSAGE from "@/graphql/mutations/ChatMessageSeenUpdate.graphql";
import { eventBus } from "../../../../utils";
import moment from "moment";

export default {
  name: "ChatMessage",
  components: { InputComponent, FileIconComponent },
  props: {
    chat: Object,
    shop: Object,
  },
  mounted() {
    
    eventBus.$on("set-message-chat", (message) => {
        console.log(message);
        
        this.message = message;
      });
      
    this.$nextTick(async () => {
      await this.loadMessageHandle();

      this.messageBodyAutoScroll();

      this.$refs.chatMessagesBody.addEventListener("scroll", (event) => {
        this.onScrollMessageHandle(event);
      });

      this.initChannelMessage();
    });
  },
  data() {
    return {
      messages: [],
      message: "",
      attachment: null,
      messageTimeoutId: null,
      first: 50,
      page: 1,
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    apollo_token() {
      return this.$store.state.apollo_token;
    },
    mediaEndpoint() {
      return this.$store.state._env.MEDIA_ENDPOINT;
    },
  },
  methods: {
    async loadMessageHandle() {
      this.loading = true;

      return this.$apollo
        .query({
          query: CHAT_MESSAGES,
          variables: {
            chat_id: this.chat.id,
            first: this.first,
            page: this.page,
          },
          fetchPolicy: "no-cache",
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.chat_messages_paginate) {
            this.messages.unshift(...data.chat_messages_paginate);
          }
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    initChannelMessage() {
      window.Echo.private(`chat-channel.${this.chat.id}`).listen("ChatMessageCreateEvent", (data) => {
        this.messages.push(data.chat_message);
      });
    },
    async sendMessageHandle(e) {
      e.preventDefault();

      this.loading = true;

      return this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            chat_id: this.chat.id,
            user_id: this.user.id,
            message: this.message,
            type: 1,
          },
          context: {
            headers: {
              "X-Socket-Id": window.Echo.socketId(),
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.ChatMessageCreate) {
            this.message = "";
            this.messages.push(data.ChatMessageCreate.chat_message);
            setTimeout(() => this.messageBodyAutoScroll(), 10);
            this.chat.latestMessage = data.ChatMessageCreate.chat_message;

            if (this.$props.shop) this.updateShopChatStore();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async sendAttachmentHandle(e) {
      e.preventDefault();

      this.loading = true;

      return this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            shop_id: this.chat.id,
            user_id: this.user.id,
            attachment: this.attachment,
            type: 1,
          },
          context: {
            headers: {
              "X-Socket-Id": window.Echo.socketId(),
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.ChatMessageCreate) {
            this.message = "";
            this.messages.push(data.ChatMessageCreate.chat_message);
            setTimeout(() => this.messageBodyAutoScroll(), 10);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async filesUpload(e) {
      e.preventDefault();

      this.loading = true;

      return this.$apollo
        .mutate({
          mutation: FILES_UPLOAD,
          variables: {
            files: [e.target.files[0]],
            path: "chat",
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          if (data.FilesUpload) {
            this.attachment = data.FilesUpload[0]?.path;
            this.sendAttachmentHandle(e);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateSeen(state, chatMessage, index) {
      if (!chatMessage.is_my) console.log(chatMessage.seen);

      if (state && !chatMessage.is_my && chatMessage.seen === 0) {
        return this.$apollo
          .mutate({
            mutation: UPDATE_SEEN_MESSAGE,
            variables: {
              message_id: chatMessage.id,
              seen: 1,
            },
            context: {
              headers: {
                "X-Socket-Id": window.Echo.socketId(),
                Authorization: "Bearer " + this.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.messages[index] = data.ChatMessageSeenUpdate;
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onScrollMessageHandle(event) {
      if (this.messageTimeoutId) clearTimeout(this.messageTimeoutId);

      this.messageTimeoutId = setTimeout(() => this.loadMessageByScrollHandle(event), 500);
    },
    async loadMessageByScrollHandle() {
      const heightContainer = this.$refs.chatMessagesBody.scrollHeight;
      const thisHeightContainer = this.$refs.chatMessagesBody.scrollTop;

      if (thisHeightContainer === 0) {
        const oldHeightContainer = heightContainer;
        this.page++;
        console.log(this.page);

        await this.loadMessageHandle();
        const newHeightContainer = this.$refs.chatMessagesBody.scrollHeight;

        setTimeout(() => {
          this.$refs.chatMessagesBody.scrollTop = newHeightContainer - oldHeightContainer;
        }, 100);
      }
    },
    messageBodyAutoScroll() {
      const chatMessagesBodyHeight = this.$refs.chatMessagesBody.scrollHeight;
      this.$refs.chatMessagesBody.scrollTo(0, chatMessagesBodyHeight);
    },
    removeChat() {
      this.$emit("remove-chat");
    },
    chatTimeConvert(date) {
      return moment(date).format("HH:mm");
    },
    getImg(attachment) {
      return this.mediaEndpoint + attachment;
    },
    updateShopChatStore() {
      const shopIndex = this.$store.state.shops.findIndex((shop) => shop.id === this.shop.id);
      this.$store.state.shops[shopIndex].chat = this.chat;
    },
  },
};
</script>

<style lang="stylus" scoped>
.chat-messages{
  height: 100%;

  &__container{
    height: 100%;
    display: flex
    flex-direction: column
  }

  &__header{
    padding 15px 25px 15px 25px
    display flex
    align-items: center;
  }

  &__title{
    display flex
    align-items: center;
  }

  &__back{
    cursor pointer
  }
}

.chat-messages-title{
  &__avatar{
    & > img{
      max-width 60px
    }
  }

  &__title{
    margin 0
  }
}

.chat-messages-body{
  height: calc(100% - 80px);
  padding 10px;
  overflow-y: auto
}

.chat-messages-body-item{
  margin 10px 0
  width: 100%;
  display flex

  &__name{
    color var(--accent)

    &--my{
      color #fff
    }
  }

  &__message {
    font-size 14px
    margin-bottom 0

    &--my{
      color #fff
    }
  }

  &__content{
    padding 10px
    width: 80%;
    border-radius 10px
    background-color #244A7F14

    &--my{
      background-color var(--accent)
      //float right
    }
  }

  &--my{
    margin 5px 0
    justify-content: flex-end;
  }

  &__time{
    display: block;
    text-align: right;
    font-size 14px

    &--my {
      color #fff
    }
  }
}

.chat-messages-actions {
  position relative
  height 60px
  bottom 0
  background-color #fff
  flex-shrink: 0;
  padding 10px
  width 100%
  display flex
  align-items center

  &__message{
    width 100%
    margin-right 10px
    margin-left 10px

    ::v-deep(.input__container){
      height: 40px;
      border-radius 50px
    }
  }

  &__send{
    display block
    position relative
    background-color var(--accent)
    border-radius 50%
    width 35px
    height 35px
    flex-shrink: 0;
    border: none;
    cursor pointer

    &-icon{
      position relative
      width 14px
      fill #fff
      top 1px
      left 1px
    }

    &[disabled] {
      opacity 0.5
    }
  }
}
</style>
