var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-messages"},[_c('div',{staticClass:"chat-messages__container"},[_c('div',{staticClass:"chat-messages__header"},[_c('span',{staticClass:"chat-messages__back",on:{"click":_vm.removeChat}},[_vm._v("←")]),_c('div',{staticClass:"chat-messages__title chat-messages-title"},[_vm._m(0),_c('div',{staticClass:"chat-messages-title__name"},[(_vm.shop)?_c('h5',{staticClass:"chat-messages-title__title"},[_vm._v(_vm._s(_vm.shop.title))]):_c('h5',{staticClass:"chat-messages-title__title"},[_vm._v("Техническая поддержка")]),_c('small',{staticClass:"chat-messages-title__subtitle"},[_vm._v(_vm._s(_vm.chat.subtitle))])])])]),_c('div',{ref:"chatMessagesBody",staticClass:"chat-messages__body chat-messages-body"},[_c('div',{staticClass:"chat-messages-body__container"},_vm._l((_vm.messages),function(item,key){return _c('div',{key:key,staticClass:"chat-messages-body__item chat-messages-body-item",class:{
            'chat-messages-body-item--my': item.is_my,
          }},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:((state) => _vm.updateSeen(state, item, key)),expression:"(state) => updateSeen(state, item, key)"}],staticClass:"chat-messages-body-item__content",class:{
              'chat-messages-body-item__content--my': item.is_my,
            }},[_c('h6',{staticClass:"chat-messages-body-item__name",class:{
                'chat-messages-body-item__name--my': item.is_my,
              }},[_vm._v("\n              "+_vm._s(item.user?.name)+"\n            ")]),_c('div',{staticClass:"chat-messages-body-item__text"},[(item.message)?_c('p',{staticClass:"chat-messages-body-item__message",class:{
                  'chat-messages-body-item__message--my': item.is_my,
                }},[_vm._v("\n                "+_vm._s(item.message)+"\n              ")]):_vm._e(),(item.attachment)?_c('div',{staticClass:"chat-messages-body-item__attachment"},[_c('img',{attrs:{"src":_vm.getImg(item.attachment)}})]):_vm._e(),_c('span',{staticClass:"chat-messages-body-item__time",class:{
                  'chat-messages-body-item__time--my': item.is_my,
                }},[_vm._v("\n                "+_vm._s(_vm.chatTimeConvert(item.created_at))+"\n              ")])])])])}),0)]),_c('form',{staticClass:"chat-messages__actions chat-messages-actions",on:{"submit":_vm.sendMessageHandle}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.filesUpload}}),_c('button',{staticClass:"chat-messages-actions__file btn btn--sm btn--hollow-dark",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$refs.file.click()}}},[_c('FileIconComponent',{staticClass:"chat-messages-actions__file-icon",attrs:{"name":"paperclip"}})],1),_c('InputComponent',{staticClass:"chat-messages-actions__message",attrs:{"type":"text","disabled":_vm.loading,"placeholder":"Напишите сообщение..."},model:{value:(_vm.message),callback:function ($$v) {_vm.message=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"message"}}),_c('button',{staticClass:"chat-messages-actions__send",attrs:{"type":"submit","disabled":_vm.message.length === 0 || _vm.loading}},[_c('FileIconComponent',{staticClass:"chat-messages-actions__send-icon",attrs:{"name":"arrow-up"}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-messages-title__avatar"},[_c('img',{attrs:{"src":"/static/images/no-image.png"}})])
}]

export { render, staticRenderFns }